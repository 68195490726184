import * as React from "react";
import {useHistory , Redirect, BrowserRouter, /*HashRouter,*/ Route, Link, withRouter, Switch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
// /import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ArrowBack from "@material-ui/icons/ArrowBack";
//import withStyles from '@material-ui/core/styles/withStyles'
import { lighten, withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
//import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Drilldown from "react-router-drilldown";

import CameraAlt from "@material-ui/icons/CameraAlt";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import Grid from "@material-ui/core/Grid";
import "./App.css";
import Amplify from "aws-amplify";
import API from "@aws-amplify/api";
import { Storage } from "aws-amplify";

import Modal from "@material-ui/core/Modal";

import Loader from "react-loader-spinner";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CryptoJS from "crypto-js";

import imageCompression from "browser-image-compression";

import LinearProgress from "@material-ui/core/LinearProgress";

var request = require("request");

var PB = function(x, y) {
  var options = {
    method: "POST",
    url:
      "https://i8qgtjixd0.execute-api.me-south-1.amazonaws.com/default/PushBullet",
    qs: { [x]: y },
    headers: {
      "cache-control": "no-cache",
      Connection: "keep-alive",
      "content-length": "",
      "accept-encoding": "gzip, deflate",
      Host: "i8qgtjixd0.execute-api.me-south-1.amazonaws.com",
      Accept: "*/*",
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };

  request(options, function(error, response, body) {
    if (error) throw new Error(error);

    console.log(body);
  });
};

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  textField: {
    maxWidth: 600,
    margin: `16px auto`,
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px"
    }
  }
});

const viewStyles = theme => ({
  root: {
    maxWidth: 600,
    margin: `16px auto`,
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px"
    }
  }
});

const OnTrackProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten("#16ba41", 0.5)
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#16ba41"
  }
})(LinearProgress);

const OffTrackProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten("#eb260c", 0.5)
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#eb260c"
  }
})(LinearProgress);

const View = withStyles(viewStyles)(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const { classes } = this.props;

      return <div className={classes.root}>{this.props.children}</div>;
    }
  }
);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "smarts",
        endpoint:
          "https://eiewmp1axl.execute-api.eu-central-1.amazonaws.com/default/",
        region: "eu-central-1"
      }
    ]
  },
  Auth: {
    identityPoolId: "eu-central-1:d7317882-6615-429d-a0bd-342da52577d3", //REQUIRED - Amazon Cognito Identity Pool ID
    region: "eu-central-1" // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: "farmjobs", //REQUIRED -  Amazon S3 bucket
      region: "eu-central-1" //OPTIONAL -  Amazon service region
    }
  }
});

//https://serverless-stack.com/chapters/configure-aws-amplify.html

class TopView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  render() {
    return (
      <View>
        <Paper>
          <List className="bggg">
            <DrilldownListItem to="/Farms">የሚገኙ ተግባሮችን ይመልከቱ</DrilldownListItem>
            {/*      <DrilldownListItem to="/Farms">Announcements</DrilldownListItem>
            <DrilldownListItem to="/Farms">Instructions</DrilldownListItem> */}
          </List>
        </Paper>
      </View>
    );
  }
}
class DrilldownListItem extends React.Component {
  constructor() {
    super();
    this.state = {
      Farms: []
    };
  }

  render() {
    //console.log(this.props.children );

    return (
      <ListItem button component={Link} {...this.props}>
        <ListItemText>
          {typeof this.props.children === "string" && (
            <>{this.props.children}</>
          )}

          {typeof this.props.children !== "string" && (
            <>
              <b>{this.props.children[0]}</b>
              <br></br>
              <span className="sub">{this.props.children[1]}</span>
              <br />
              <span className="blue sub">{this.props.children[2]}</span>
              <br />
              <span className="sub">{this.props.children[3]}</span>
            </>
          )}
        </ListItemText>
        <ListItemSecondaryAction>
          <ChevronRight />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

class FarmsView extends React.Component {
  constructor() {
    super();
    this.state = {
      Farms: []
    };
  }

  getFarms = () => {
    this.setState({
      Farms: this.props.pulledFarms
    });
  };

  componentDidMount() {
    this.getFarms();
  }

  render() {
    return (
      <div
        style={
          {
            //height: '100vh'
          }
        }
      >
        <View>
          {/*  <BackButton /> */}

          {this.props.isLoading ? (
            <div
              style={{
                color: "#00617a",
                margin: "auto",
                position: "relative",
                top: window.innerHeight / 2 - 250 + "px",
                height: window.innerHeight / 2 + "px"
              }}
            >
              {" "}
              <center>
                {" "}
                Loading...
                <br />
                ውሂብ በመጫን ላይ...
                <br />
                <Loader type="Bars" color="#00617a" height={50} width={50} />
              </center>
              <br />{" "}
            </div>
          ) : (
            <div>
              <Typography variant="h6">Welcome! Today's is <span style={{color:"#00617a"}}>{new Date().toDateString()}</span></Typography><br />
              
              <Typography variant="h5">Select a farm | እርሻ ይምረጡ</Typography>
              <br />
              <Paper>
                <List className="bggg">
                  {this.state.Farms.map(({ farmName }, key) => (
                    <DrilldownListItem
                      farm={farmName}
                      key={key}
                      to={`${this.props.match.url}${farmName}`}
                    >
                      {farmName}
                    </DrilldownListItem>
                  ))}
                </List>
              </Paper>
            </div>
          )}
        </View>
      </div>
    );
  }
}

class FarmView extends React.Component {
  constructor() {
    super();
    this.state = {
      Blocks: [],
      isLoading: true
    };
  }

  getBlocks = () => {
    //setInterval(this.incrementSeconds, 1000);

    let apiName = "smarts";
    let path = "pullActuals/?reqFarm=" + this.props.match.params.id;
    let options = {
      headers: { "Access-Control-Allow-Origin": "*" }
    };
    API.get(apiName, path, options)
      .then(response => {
        this.setState(
          {
            Blocks: response.Blocks.sort(function(a, b) {
              return a.blockName.localeCompare(b.blockName);
            }),
            isLoading: false
          },
          () => {
            //console.log(this.state.Blocks)
          }
        );
        return response;
      })

      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getBlocks();
  }

  render() {
    //console.log(this.props.match.params.id);

    // console.log(this.props);
    return (
      <div id="aaa222">
        <View>
          <BackButton />

          {this.state.isLoading ? (
            <div>
              <div
                style={{
                  //       margin: "auto", width: "80%", padding: "10px",position: "relative",top: "200px",
                  color: "#00617a",
                  margin: "auto",
                  position: "relative",
                  top: window.innerHeight / 2 - 250 + "px",
                  height: window.innerHeight / 2 + "px"
                }}
              >
                {" "}
                <center>
                  {" "}
                  Loading...
                  <br />
                  ውሂብ በመጫን ላይ...
                  <br />
                  <Loader type="Bars" color="#00617a" height={50} width={50} />
                </center>
                <br />{" "}
              </div>
            </div>
          ) : (
            <div>
              <Typography variant="h5">
                Select a block | አንድ ብሎክ ይምረጡ
              </Typography>
              <br />
              <Paper>
                <List className="bggg">
                  {this.state.Blocks.map(({ blockName }, key) => (
                    <DrilldownListItem
                      farmname={this.props.match.params.id}
                      key={key}
                      to={{
                        pathname: `${this.props.match.url}/${blockName}`,
                        state: {
                          farmname: this.props.match.params.id
                        }
                      }}
                    >
                      {blockName}
                    </DrilldownListItem>
                  ))}
                </List>
              </Paper>
            </div>
          )}
        </View>
      </div>
    );
  }
}

class JobsView extends React.Component {
  constructor() {
    super();
    this.state = {
      Jobs: [],
      isLoading: true,
      dialogStatus: true
    };
  }

  closeDialog = () => {
    this.setState({
      dialogStatus: false
    });
  };

  getJobs = async () => {
    var parts = this.props.match.url.split("/");
    //console.log(parts[2]);

    var farm = parts[1];

    let apiName = "smarts";
    let path =
      "pullActuals/?reqFarm=" +
      farm +
      "&reqBlock=" +
      this.props.match.params.id;
    let options = {
      headers: { "Access-Control-Allow-Origin": "*" }
    };
    API.get(apiName, path, options)
      .then(response => {
        var range = 9
        var d = 7 - range;
        var da = [];
        var da2 = [];

        for (let index = 0; index < range; index++) {
          /*   if (
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + d
    ).getDay() === 0
  ) {
    d = d + 1;
  }

  else if (
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + d
    ).getDay() === 6
  ) {
    d = d + 2;
  } */


    var tempDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + d
    ).toDateString()

    da2[tempDate] = {
      index : index
    }



          da[index] = {
            order: index + 1,
            date: tempDate,
            data: []
          };

          d = d + 1;
        }

        da.push({ order: range+1, date: "Older", data: [] });

        console.log(da)

        response.result.map(x => {
          
          if (da2[new Date(x.Start+"T12:00:00Z").toDateString()]) {

            var ti = da2[new Date(x.Start+"T12:00:00Z").toDateString()].index
            da[ti].data.push(x)
            
          } else {
            da[range].data.push(x);
          }
          return true;
        });
        this.setState(
          {
            Jobs: response.result.sort(),
            isLoading: false,
            da: da
          },
          () => {
           // console.log(this.state);
          }
        );
        return response;
      })

      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getJobs();
  }

  render() {
    return (
      <div
        style={
          {
            //height: '100vh'
          }
        }
      >
        <View>
          <BackButton />{" "}
          <span style={{ position: "absolute", left: "40%", color: "#00617a" }}>
            {decodeURI(
              //    this.props.location.search.match(/(?<=sendStatus=).*/g)
              this.props.location.search.match(/\w+$/g)
            ) === "success" ? (
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={this.state.dialogStatus}
              >
                <DialogContent dividers>
                  <Typography gutterBottom color="primary">
                    Update sent to HQ!
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeDialog}>OK</Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
          </span>
          {this.state.isLoading ? (
            <div
              style={{
                color: "#00617a",
                margin: "auto",
                position: "relative",
                top: window.innerHeight / 2 - 250 + "px",
                height: window.innerHeight / 2 + "px"
              }}
            >
              {" "}
              <center>
                {" "}
                Loading...
                <br />
                ውሂብ በመጫን ላይ....
                <br />
                <br />
                <Loader type="Bars" color="#00617a" height={50} width={50} />
              </center>
              <br />{" "}
            </div>
          ) : (
            <div>
              <Typography variant="h5">Select a job | ሥራ ጀምር</Typography>
              <br />
              <Paper>
                <List className="bggg">
                  {this.state.da.map(({ date, data }, key) => (
                    <div key={key} className="bggg">
                      <ExpansionPanel
                        square={false}
                        defaultExpanded={false}
                      >
                        <ExpansionPanelSummary
                          style={{
                            color: "#3f51b5",
                            backgroundColor: "#f7f5e8"
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{date}</Typography>
                        </ExpansionPanelSummary>

                        {data.map(
                          (
                            {
                              EnglishName,
                              JobPlanNumber,
                              NumOfProofs,
                              Status,
                              End,
                              ActivityName,
                              ActualTrackingRowNumber,
                              ReportedSoFar,
                              Quantity,
                              Start,
                              perc = (ReportedSoFar / Quantity) * 100,
                              duration = Math.round(
                                (new Date(End) - new Date(Start)) /
                                  (1000 * 60 * 60 * 24)
                              ) + 1,
                              timePassed = Math.round(
                                (new Date() - new Date(Start)) /
                                  (1000 * 60 * 60 * 24)
                              ) + 1,
                              timepassedPerc = timePassed > duration
                                ? 100
                                : (timePassed / duration) * 100,
                              color = perc < timepassedPerc
                                ? "pink"
                                : "#d5e8da",
                              remaining = Quantity > ReportedSoFar
                                ? Quantity - ReportedSoFar
                                : 0,
                              UoM
                            },
                            key
                          ) => (
                            <DrilldownListItem
                              style={{
                                background:
                                  "linear-gradient(to right, " +
                                  color +
                                  " " +
                                  perc +
                                  "% , transparent " +
                                  (perc + 1) +
                                  "%)"
                              }}
                              key={ActualTrackingRowNumber}
                              to={`${this.props.match.url}/${ActualTrackingRowNumber}`}
                            >
                              {ActivityName === "#NO MATCH" ?  "**Translation Missing**": ActivityName }
                              {EnglishName}
                              {JobPlanNumber + " - ምክንያት በ:" + End}
                              {Status + " (" + remaining + " " + UoM + " ቀሪ)"}
                            </DrilldownListItem>
                          )
                        )}
                      </ExpansionPanel>{" "}
                      <br></br>
                    </div>
                  ))}
                </List>
              </Paper>
            </div>
          )}
        </View>
      </div>
    );
  }
}

const JobView = withStyles(styles)(
    
  class extends React.Component {
    
  
    
    _isMounted = false;

    constructor(props) {
      super(props);
      this.state = {
        job: [],
        coords: [],
        actualDetails: {
          qu: null,
          actualComment: "No comment",
          file: undefined,
          final: false
        },
        isLoading: true
      };
    }

    getQu = () => {
      if (
        Number(this.state.actualDetails.qu) <=
        Number(this.state.job[0].ReportedSoFar)
      ) {
        return 0;
      } else {
        return (
          Number(this.state.actualDetails.qu) -
          Number(this.state.job[0].ReportedSoFar)
        );
      }
    };

    submitProof = (e, x) => {
      
     
      
      this.setState({ isLoading: true });

      const file = e.target.files[0];
      const filename = `${Date.now()}-${file.name}`;

      var options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      imageCompression(file, options)
        .then(compressedFile => {
          Storage.put(filename, compressedFile, {
            contentType: "image/png"
          })
            .then(result => {
              var tempObj = this.state.actualDetails;

              tempObj.file = filename;

              if (x === true) {
                tempObj.final = true;
              }

              this.setState(
                { actualDetails: tempObj },

                () => {
                  let apiName = "smarts";
                  let path =
                    "addProof/?jobId=" +
                    this.props.match.params.id +
                    "&lat=" +
                    this.state.coords.latitude +
                    "&lon=" +
                    this.state.coords.longitude +
                    "&qu=" +
                    this.getQu() +
                    "&comment=" +
                    this.state.actualDetails.actualComment +
                    "&file=" +
                    this.state.actualDetails.file +
                    "&final=" +
                    this.state.actualDetails.final+
                    "&user=" +
                    this.props.loggedUsers +
                    "&URL=" +
                    encodeURIComponent(window.location);
                  let options = {
                    headers: { "Access-Control-Allow-Origin": "*" }
                  };

                  // console.log(path);

                  API.get(apiName, path, options)
                    .then(response => {
                      this.setState({ isLoading: false });

                      const sepIndex = window.location.pathname.lastIndexOf("/");
                      const to =
                        sepIndex <= 0
                          ? "/"
                          : window.location.pathname.substring(0, sepIndex);
                      
                   
                   
                   this.props.history.push(to + "?sendStatus=success")    
                      
                    })
                    .catch(error => {
                      console.log(error);
                      alert("error");
                    //  PB("inputPath", path);
                    //  PB("error", error);
                      this.setState({ isLoading: false });
                    });
                }
              );
            })

            .catch(err => console.log(err));
        })
        .catch(function(error) {
          console.log(error.message);
        });
    };
    
    

    recordChange = (e, x) => {
      
     
      
      
      
      var tempObj = this.state.actualDetails;

      if (x) {
        tempObj[e.target.id] = x;
      } else {
        tempObj[e.target.id] = e.target.value;
      }

      this.setState({ actualDetails: tempObj }, () => {
        /* console.log(this.state) */
      });
    };

    jobState = () => {
      this._isMounted = true;
      let apiName = "smarts";
      let path = "pullActuals/?jobId=" + this.props.match.params.id;
      let options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      API.get(apiName, path, options)
        .then(response => {
          if (this._isMounted) {
            this.setState(
              {
                job: response.result,
                isLoading: false
              },
              () => {
                /* console.log(this.state) */
              }
            );
          }
          /* console.log(response);  */
        })

        .catch(error => {
          console.log(error);
        });
    };

    componentDidMount() {
      this.jobState();
      this.getLocation();
      
    }

    componentWillUnmount() {
      //console.log(window.location)
      this._isMounted = false;
      this.setState({ isLoading: false });
      
          }

    getLocation = () => {
      const geolocation = navigator.geolocation;

      // eslint-disable-next-line
      const location = new Promise((resolve, reject) => {
        if (!geolocation) {
          reject(new Error("Not Supported"));
        }

        geolocation.getCurrentPosition(
          position => {
            resolve(position);

            this.setState({
              coords: position.coords
            });
          },
          () => {
            alert("Must allow location sharing");

            window.location.replace("/");

            console.log(new Error("Permission denied"));
          }
        );
      });
    };

    handleSliderChange = (event, newValue) => {
      var tempObj = this.state.actualDetails;

      tempObj.qu = Number(newValue);

      this.setState({
        actualDetails: tempObj
      });
    };

    handleInputChange = event => {
      this.recordChange(event);

      var tempObj = this.state.actualDetails;

      tempObj.qu = event.target.value === "" ? "" : Number(event.target.value);

      this.setState({
        actualDetails: tempObj
      });
    };

    render() {
      const classes = this.props;

      return (
        <div
          style={
            {
              ////height: '100vh'
            }
          }
        >
          <View>
            <BackButton />

            {this.state.isLoading ? (
              <div
                style={{
                  color: "#00617a",
                  margin: "auto",
                  position: "relative",
                  top: window.innerHeight / 2 - 250 + "px",
                  height: window.innerHeight / 2 + "px"
                }}
              >
                {" "}
                <center>
                  {" "}
                  Loading...
                  <br />
                  ውሂብ በመጫን ላይ...
                  <br />
                  <Loader type="Bars" color="#00617a" height={50} width={50} />
                </center>
                <br />{" "}
              </div>
            ) : (
              this.state.job.map(
                (
                  {
                    JobPlanNumber,
                    NumOfProofs,
                    ActualTrackingRowNumber,
                    ActivityName,
                    EnglishName,
                    Start,
                    End,
                    Quantity,
                    ReportedSoFar,
                    perc = (ReportedSoFar / Quantity) * 100,
                    duration = Math.round(
                      (new Date(End) - new Date(Start)) / (1000 * 60 * 60 * 24)
                    ) + 1,
                    timePassed = Math.round(
                      (new Date() - new Date(Start)) / (1000 * 60 * 60 * 24)
                    ) + 1,
                    remainingTime = new Date() > new Date(End)
                      ? 1
                      : duration - timePassed,
                    workersPerUom,
                    originalRate = Math.round(Quantity * workersPerUom),
                    newRate = Math.round(
                      (Quantity - ReportedSoFar) * workersPerUom
                    ),
                    UoM
                  },
                  key
                ) => (
                  <Card key={key}>
                    {NumOfProofs.match(/([\d])/g)[0] === "0" ? (
                      <CardContent className="bggg">
                        <Grid container spacing={8} style={{ padding: "20px" }}>
                          <Grid item xs={12} className="jobInfo">
                            <center>
                              <Typography variant="h6">
                                {JobPlanNumber}
                              </Typography>
                              <Typography variant="h5">
                                {ActivityName === "#NO MATCH" ? "**Translation Missing**" : ActivityName }
                              </Typography>
                              <Typography>
                                {"("+EnglishName+")"}
                              </Typography>
                            </center>
                          </Grid>

                          <Grid
                            container
                            alignItems="center"
                            className="jobInfo"
                          >
                            <Grid item xs={12} className="jobInfo"></Grid>
                            <Grid item xs={2} className="jobInfo">
                              Start
                              <br />
                              ጀምር
                            </Grid>
                            <Grid item xs={4} className="jobInfo">
                              {Start}
                            </Grid>
                            <Grid item xs={2} className="jobInfo">
                              End:
                              <br />
                              ጨርስ:
                            </Grid>
                            <Grid item xs={4} className="jobInfo">
                              {End}
                            </Grid>
                            <Grid item xs={12} className="jobInfo">
                              <p></p>
                            </Grid>

                            <Grid item xs={7} className="jobInfo">
                              {"Requested Qty:"}
                              <br />
                              {"የተጠየቀ ብዛት:"}
                            </Grid>
                            <Grid item xs={5} className="jobInfo">
                              <b>
                                {Quantity + " "}
                                <i>{UoM}</i>
                              </b>
                            </Grid>

                            <Grid item xs={12} className="jobInfo">
                              {/*  {Math.round(originalRate) + " worker(s)"} */}
                            </Grid>
                          </Grid>

                          <Grid item xs={12} className="info">
                            {perc > timePassed ? (
                              <OnTrackProgress
                                className={classes.margin}
                                variant="determinate"
                                value={perc}
                              />
                            ) : (
                              <OffTrackProgress
                                className={classes.margin}
                                variant="determinate"
                                value={perc}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12}>
                            <p />
                          </Grid>

                          <Typography variant="h6">
                            Please take a picture of the area to start the work
                            <br />
                            ስራውን ለመጀመር እባክዎን የአከባቢውን የውስጥ ስዕል ያንሱ
                          </Typography>

                          <Grid item xs={12}>
                            <p />
                          </Grid>

                          <Grid item xs={6}>
                            <center>
                              <input
                                hidden={true}
                                size="60"
                                type="file"
                                name="file"
                                id="file"
                                accept="image/*"
                                capture="capture"
                                onChange={this.submitProof}
                              ></input>

                              <label htmlFor="file">
                                <CameraAlt
                                  style={{ color: "green", fontSize: 50 }}
                                  htmlFor="file"
                                />
                                <br />
                                Send Update
                                <br />
                                ተግባር ጀምር
                              </label>
                            </center>
                          </Grid>
                          <Grid item xs={6}>
                            <center>
                              {" "}
                              <CallModal sp={this.submitProof} />
                            </center>
                          </Grid>
                        </Grid>
                      </CardContent>
                    ) : (
                      <CardContent className="bggg">
                        <Grid
                          container
                          alignItems="center"
                          spacing={8}
                          style={{ padding: "20px" }}
                        >
                          <Grid item xs={12} className="jobInfo">
                            <center>
                              <Typography variant="h6">
                                {JobPlanNumber}
                              </Typography>
                              <Typography variant="h5">
                                {ActivityName === "#NO MATCH" ? "**Translation Missing**" : ActivityName}
                              </Typography>
                              <Typography>
                                {"("+EnglishName+")"}
                              </Typography>
                            </center>
                          </Grid>

                          <Grid
                            container
                            alignItems="center"
                            className="jobInfo"
                          >
                            <Grid item xs={12} className="jobInfo"></Grid>
                            <Grid item xs={2} className="jobInfo">
                              Start:<br/>ጀምር:
                            </Grid>
                            <Grid item xs={4} className="jobInfo">
                              {Start}
                            </Grid>
                            <Grid item xs={2} className="jobInfo">
                              End:<br/>ጨርስ:
                            </Grid>
                            <Grid item xs={4} className="jobInfo">
                              {End}
                            </Grid>
                            <Grid item xs={12} className="jobInfo">
                              <p></p>
                            </Grid>

                            <Grid item xs={7} className="jobInfo">
                              {"Requested Qty:"}
                              <br />
                              {"የተጠየቀ ብዛት:"}
                            </Grid>
                            <Grid item xs={5} className="jobInfo">
                              <b>
                                {Quantity + " "}
                                <i>{UoM}</i>
                              </b>
                            </Grid>

                            <Grid item xs={12} className="jobInfo">
                              {/*  {Math.round(originalRate) + " worker(s)"} */}
                            </Grid>
                          </Grid>

                          <Grid item xs={12} className="info">
                            {perc > timePassed ? (
                              <OnTrackProgress
                                className={classes.margin}
                                variant="determinate"
                                value={perc}
                              />
                            ) : (
                              <OffTrackProgress
                                className={classes.margin}
                                variant="determinate"
                                value={perc}
                              />
                            )}
                          </Grid>
                          <Grid container alignItems="center" className="info">
                            <Grid item xs={7} spacing={3} className="info">
                              {"Reported so far:"}
                              <br />
                              {"እስካሁን ተጠናቋል: "}
                            </Grid>
                            <Grid item xs={5} className="info">
                              {ReportedSoFar}
                              <i>{UoM}</i> {" (" + Math.round(perc) + "%)"}
                            </Grid>
                            <Grid item xs={12} className="info">
                              <p></p>
                            </Grid>

                            <Grid item xs={7} className="info">
                              Remaining: <br /> ለማጠናቀቅ:
                            </Grid>
                            <Grid item xs={5} className="info">
                              {Quantity - ReportedSoFar} <i>{UoM}</i>{" "}
                              {" (" + Math.round(100 - perc) + "%)"}
                            </Grid>
                            <Grid item xs={12} className="info">
                              <p></p>
                            </Grid>

                            <Grid item xs={7} className="info">
                              You should hire: <br /> መቅጠር አለብዎት:
                            </Grid>
                            <Grid item xs={5} className="info">
                              {newRate} workers
                              <br />
                              (የጉልበት ሥራ ወንዶች)
                            </Grid>
                            <Grid className="info" item xs={12}>
                              <p></p>
                            </Grid>

                            <Grid item xs={7} className="info">
                              {"Pictures taken so far:"}
                              <br></br>
                              {"እስካሁን ድረስ የተወሰዱ ስዕሎች: "}
                            </Grid>
                            <Grid item xs={5} className="info">
                              {NumOfProofs.match(/([\d])/g)}
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <center>
                              <Typography variant="h6">
                                Send a new update to the office:
                                <br />
                                አዲስ ዝመና ወደ ቢሮ ይላኩ:
                              </Typography>
                              <br />
                            </center>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography gutterBottom>
                              Indicate total amount completed
                              <br />
                              የተጠናቀቀውን መጠን ያመልክቱ
                            </Typography>
                            {/* https://codesandbox.io/s/7uinv */}
                            <Slider
                              id="qu"
                              style={{
                                width: "81%",
                                margin: "1%",
                                verticalAlign: "middle",
                                color: "cornflowerblue"
                              }}
                              valueLabelDisplay="auto"
                              step={0.25}
                              min={ReportedSoFar}
                              max={Quantity}
                              onChangeCommitted={this.recordChange}
                              onChange={this.handleSliderChange}
                              value={Number(this.state.actualDetails.qu)}
                            />
                            <Input
                              id="qu"
                              style={{
                                width: "15%",
                                margin: "1%",
                                verticalAlign: "middle",
                                color: "cornflowerblue"
                              }}
                              value={this.state.actualDetails.qu || ''}
                              placeholder={ReportedSoFar + ""}
                              margin="dense"
                              keyboardtype="numeric"
                              onChange={this.handleInputChange}
                              // onBlur={handleBlur}
                              inputProps={{
                                step: 0.25,
                                max: Quantity * 1.5,
                                type: "number",
                                "aria-labelledby": "input-slider"
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              onChange={this.recordChange}
                              id="actualComment"
                              label="Add your comments ... | አስተያየቶችዎን ያክሉ ..."
                              multiline
                              rowsMax="3"
                              rows="2"
                              margin="normal"
                              variant="outlined"
                              className={classes.textField}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <center>
                              <input
                                hidden={true}
                                size="60"
                                type="file"
                                name="file"
                                id="file"
                                accept="image/*"
                                capture="capture"
                                onChange={this.submitProof}
                              ></input>

                              <label htmlFor="file">
                                <CameraAlt
                                  style={{ color: "green", fontSize: 50 }}
                                  htmlFor="file"
                                />
                                <br /> Send an update <br />
                                ዝመና ይላኩ
                              </label>
                            </center>
                          </Grid>
                          <Grid item xs={6}>
                            <center>
                              <CallModal sp={this.submitProof} />
                            </center>
                          </Grid>
                        </Grid>
                      </CardContent>
                    )}
                  </Card>
                )
              )
            )}
          </View>
        </div>
      );
    }
  }
);

const BackButton = withRouter(
  class extends React.Component {
    constructor() {
      super();
      this.state = {};
    }

    render() {
      const sepIndex = this.props.match.url.lastIndexOf("/");
      const to =
        sepIndex <= 0 ? "/" : this.props.match.url.substring(0, sepIndex);

      return (
        <Button to={to} component={Link}>
          <ArrowBack /> Back | ቀዳሚ ገጽ
        </Button>
      );
    }
  }
);

const useStyles2 = theme => ({
  paper: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5]

    //padding: theme.spacing(2, 4, 3),
  }
});

function getModalStyle() {
  const top = 30;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const CallModal = withStyles(useStyles2)(
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        modalStyle: getModalStyle(),
        open: false,
        setOpen: false
      };
    }

    handleOpen = () => {
      this.setState({
        open: true
      });
    };

    handleClose = () => {
      this.setState({
        open: false
      });
    };

    render() {
      const { classes } = this.props;

      return (
        <div>
          <label>
            <AssignmentTurnedInIcon
              style={{ color: "blue", fontSize: 50 }}
              onClick={this.handleOpen}
            />
            <br />
            Close job
            <br />
            ሥራውን ዝጋ
          </label>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div style={this.state.modalStyle} className={classes.paper}>
             <div style = {{marginLeft: '20px', marginRight: '20px'}}>
             <h2 id="simple-modal-title">ተግባር ዝጋ?</h2>
              <p id="simple-modal-description">
                Are you ready to close the job and submit your last picture to a supervisor?
                <br/><br/>
                ስራውን ለመዝጋት እና የመጨረሻውን ስዕልዎን ለተቆጣጣሪ ለማስገባት ዝግጁ ነዎት?
              </p>
              </div>

              <div align="right">
                <input
                  hidden={true}
                  size="60"
                  type="file"
                  name="fileC"
                  id="fileC"
                  accept="image/*"
                  capture="capture"
                  onChange={e => this.props.sp(e, true)}
                ></input>

                <Button
                  htmlFor="fileC"
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="secondary"
                >
                  <label htmlFor="fileC">Yes | አዎ</label>
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
);

class App extends React.Component {
  

    
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      date: new Date(),
      Farms: [],
      isLoading: false,
      inputDetails: [],
      isAuthLoading: false,
      user: ''
    };
  }

  incrementSeconds = () => {
    this.setState({
      date: new Date()
    });
  };

  getData = () => {
    //setInterval(this.incrementSeconds, 1000);

    let apiName = "smarts";
    let path = "pullActuals/?jobId=";
    let options = {
      headers: { "Access-Control-Allow-Origin": "*" }
    };
    API.get(apiName, path, options)
      .then(response => {
        this.setState(
          {
            Farms: response.Farms,
            isLoading: false
          },
          () => {
            /* console.log(this.state) */
          }
        );
        return response;
      })

      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.checkAuth();

    this.getData();
  }

  checkAuth = () => {
    let apiName = "smarts";
    let path = "coffeelogin/?guid=guid" + this.guid();

    let options = {
      headers: { "Access-Control-Allow-Origin": "*" }
    };
    API.get(apiName, path, options).then(response => {
      if (response.session === "yes") {
        this.setState({
          isAuthenticated: true,
          user: response.user 
        });
      }
    });
  };

  capture = () => {
    console.log(this);
  };

  login = (e, x) => {
    if (x === false) {
      let apiName = "smarts";
      let path = "coffeelogin/?logout=yes&guid=guid" + this.guid();

      let options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      API.get(apiName, path, options).then(response => {
        /*console.log(response);*/

        if (response.loggedout === "yes") {
          this.setState({
            isAuthenticated: x
          });
        } else {
          alert("error");
        }
      });
    } else {
      this.setState({
        isAuthLoading: true
      });

      var cipheruser = CryptoJS.AES.encrypt(
        this.state.inputDetails.userName,
        "guid" + this.guid()
      );
      var cipherpass = CryptoJS.AES.encrypt(
        this.state.inputDetails.userPassword,
        "guid" + this.guid()
      );

      let apiName = "smarts";
      let path =
        "coffeelogin/?user=" +
        encodeURIComponent(cipheruser.toString()) +
        "&pass=" +
        encodeURIComponent(cipherpass.toString()) +
        "&guid=guid" +
        this.guid();

      let options = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      API.get(apiName, path, options).then(response => {
        console.log(response)
        if (response.allow === "yes") {
          this.setState({
            isAuthenticated: true,
            isAuthLoading: false,
            user: this.state.inputDetails.userName
          });
        } else {
          this.setState({
            isAuthLoading: false
          });

          alert("Wrong Password");
        }
      });
    }
  };

  inputChange = (e, x) => {
    var tempObj = this.state.inputDetails;

    tempObj[e.target.id] = e.target.value;

    this.setState({ inputDetails: tempObj }, () => {
      /*  console.log(this.state) */
    });
  };

  guid = () => {
    var nav = window.navigator;
    var screen = window.screen;
    var guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, "");
    guid += nav.plugins.length;
    guid += screen.height || "";
    guid += screen.width || "";
    guid += screen.pixelDepth || "";

    return guid;
  };

  render() {
    return (
      <div>
        {this.state.isAuthenticated ? (
          <div>
            <BrowserRouter>
              <div>
            
                  <Grid container   className="appHeader" alignItems="center">
                    <Grid item xs={8}>
                      <a className="homeURL" href="/">
                        <Typography style={{ marginLeft: "11px" }}>
                          BAGERSH COFFEE FARMS
                        </Typography>
                      </a>
                      <Typography style={{ marginLeft: "11px" }} >
                    Logged in as: {this.state.user}
                        </Typography>
                    </Grid>
                    <Grid  item xs={4} style={{ margin: "-5px" }} >
                      <Button
                        type="button"
                        onClick={e => this.login(e, false)}
                        variant="contained"
                        color="#d6cac2"
                        size = "small"
                        fullWidth
                        
                      >
                        Logout | ውጣ
                      </Button>
                    </Grid>
            </Grid>
                

                <Drilldown animateHeight={false}>
                  <Switch>
                    {/* <Route path="/" exact component={TopView} /> */}
                    <Route
                      key={1}
                      strict
                      path={["/", "/Farms"]}
                      exact
                      component={props => (
                        <FarmsView
                          {...props}
                          isLoading={this.state.isLoading}
                          pulledFarms={this.state.Farms}
                        />
                      )}
                    />
                    <Route key={2} path="/:id" exact component={FarmView} />
                    <Route key={3} path="/:id/:id" exact component={JobsView} />
                    <Route
                      key={4}
                      path="/:id/:id/:id"
                      exact
                      component={props => (
                        <JobView
                          {...props}
                          loggedUsers={this.state.user}
                        />
                      )}
                    />
                    <Route
                      key={5}
                      path="/:id/:id/:id/:id"
                      exact
                      component={TopView}
                    />
              
                    
                    
                  </Switch>
                  {/*     <Route key={2} path="/Farms/Blocks" exact component={BlockView} />
        <Route key={3} path="/Farms/Blocks/:id" exact component={ActivityView} /> */}
                </Drilldown>
              </div>
            </BrowserRouter>
          </div>
        ) : (
          <div
            style={{
              margin: "auto",
              width: "80%",
              padding: "10px",
              position: "relative"
            }}
          >
            {this.state.isAuthLoading ? (
              <div
                style={{
                  color: "#00617a",
                  margin: "auto",
                  position: "relative",
                  top: window.innerHeight / 2 - 250 + "px",
                  height: window.innerHeight / 2 + "px"
                }}
              >
                {" "}
                <center>
                  {" "}
                  Loading...
                  <br />
                  ውሂብ በመጫን ላይ...
                  <br />
                  <Loader type="Bars" color="#00617a" height={50} width={50} />
                </center>
                <br />{" "}
              </div>
            ) : (
              <form>
                <br />
                <br />
                <br />
                <br />
                <center>
                  <Typography variant="h6">
                    BAGERSH COFFEE FARMS <br />
                    የቡና እርሻ
                  </Typography>
                </center>
                <br />
                <br />
                <FormControl fullWidth={true} variant="outlined">
                  <br />
                  <br />
                  <br />
                  <InputLabel htmlFor="userName">
                    Username | የተጠቃሚ ስም
                  </InputLabel>

                  <OutlinedInput
                    id="userName"
                    labelWidth={190}
                    onChange={this.inputChange}
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth={true} variant="outlined">
                  <InputLabel htmlFor="userPassword">
                    Password | የይለፍ ቃል
                  </InputLabel>

                  <OutlinedInput
                    id="userPassword"
                    labelWidth={155}
                    type="password"
                    onChange={this.inputChange}
                  />
                </FormControl>
                <br />
                <br />
                <br />

                <Button
                  type="submit"
                  onClick={e => this.login(e, true)}
                  style={{ float: "right" }}
                  variant="contained"
                  color="primary"
                >
                  Login | ግባ
                </Button>
              </form>
            )}
          </div>
        )}
      </div>
    );
  }
}


export default withStyles(styles)(App);
